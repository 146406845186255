import {
  Box,
  Burger,
  Avatar,
  Header,
  Group,
  Flex,
  IoIosLogOut,
  useStyles,
  LoadingOverlay,
  useDisclosure,
  BsThreeDotsVertical,
  Text,
  Button,
  Popover,
  images,
  IoIosNotifications,
  ModalComponent,
  NotificationComponent,
  useMediaQuery,
  em,
} from '@mfe/js-asulado-uiutils';
import { navigateToUrl } from 'single-spa';
import { useState, useEffect } from 'react';
import {
  useMsal,
  useIsAuthenticated,
  handleLogout,
  MsalProvider,
  msalInstance,
} from '@mfe/react-asulado-msal';
import {
  errorApiStore,
  subscribe,
} from '@mfe/ts-asulado-utilitaries';

export default function HeaderComponent() {
  const instance = msalInstance;
  return (
    
      <HeaderForm instance={instance} />
    
  );
}

export const HeaderForm = ({ instance }) => {
  const [loading, setLoading] = useState(false);

  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const [openModalToken, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const { accounts } = useMsal();
  const [tokenApi, setTokenApi] = useState('');

  const isAuthenticated = useIsAuthenticated();
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const [showNotification, setShowNotification] = useState(
    errorApiStore.isError
  );

  useEffect(() => {
    const modalTimer = setInterval(openModal, 3600000);
    return () => clearInterval(modalTimer);
  }, []);


  useEffect(() => {
    const unsubscribe = subscribe(errorApiStore, () =>
      setShowNotification(errorApiStore.isError)
    );
    return unsubscribe;
  }, []);

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: '#2EBADA' }}
      />
    );
  }

  
  //return isAuthenticated ? (
    <>
      {/* <NotificationComponent
        showNotification={showNotification}
        requestErrorMessage={errorApiStore.requestErrorMessage}
        buttonMessage={errorApiStore.buttonMessage}
      />
      <ModalComponent
        opened={openModalToken}
        onClose={() => {
          closeModal();
          location.reload();
        }}
        title='Alerta'
      >
        <Text fw={500} color='' ta='center'>
          ¿Desea continuar en la sesión?
        </Text>
        <Flex align='center' gap={12} mt={16}>
          <Button
            fullWidth
            className={classes.buttonOutline}
            onClick={() =>
              handleLogout(
                accounts[0].homeAccountId,
                setLoading(true),
                instance
              )
            }
          >
            Cerrar sesión
          </Button>
          <Button
            fullWidth
            className={classes.button}
            onClick={() => location.reload()}
          >
            Continuar
          </Button>
        </Flex>
      </ModalComponent> */}

      <Header bg='#1daccd'>
        <Flex justify='flex-end' align='center' direction='row' padding='20' px={10} py={10}>
          <Group className={classes.links}>
            <Avatar shadow='xl' size='md' src={images.AvatarAdmin} />
            <Box>
              <Text fw={500} color='#e8e8e0' size='sm'>
                {accounts[0].name || 'Usuario'}
              </Text>
              <Text fw={300} color='#e8e8e0' size='xs'>
                {accounts[0].username || 'user@email.com'}
              </Text>
            </Box>
            <IoIosLogOut
              // onClick={() =>
              //   handleLogout(
              //     setLoading(true),
              //     accounts[0].homeAccountId,
              //     instance
              //   )
              // }
              onClick={() =>{
                setLoading(true),
                handleLogout(
                  accounts[0].homeAccountId,
                  setLoading(true),
                  instance
                )
              }
              }
              color='#e8e8e0'
              size='1.5rem'
              cursor='pointer'
            />
          </Group>
        </Flex>
      </Header>
    </>
  //) : null;
};
